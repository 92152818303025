body {
  margin: 0;
}

.airy {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-header {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;

    &-login {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    img {
      height: 80px;
      width: 80px;
    }

    button {
      height: 35px;
      padding: 5px 20px;
      font-weight: bold;
      background: #2A52A4;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
      .airy-body-item {
        flex-direction: column;

        &.reverse {
          flex-direction: column-reverse;
        }
      }
    }

    &-item {
      width: 100%;
      min-height: calc(100vh - 80px);
      display: flex!important;
      justify-content: center;
      align-items: center;

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        max-width: 1200px;
      }

      &-payment {
        width: 500px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .payment-button {
          width: 300px;
        }

        button {
          width: 300px;
          height: 43px;
          border: 1px solid #2a52a4;
          box-shadow: 0 2px 6px 0 #626262;
          border-radius: 5px;
          background: #2a52a4;
          color: white;
          font-size: 18px;
          font-weight: bold;
        }

        select {
          width: 300px;
          height: 48px;
          border-radius: 5px;
          padding: 0 20px;
          color: #7A7A7A;
          border: 1px solid #7A7A7A;
          font-size: 20px;
        }

        p {
          width: 300px;
          margin: 0;
          font-size: 18px;
          color: #7A7A7A;
          font-weight: bold;
          padding-left: 30px;

          b {
            color: #2A52A4;
          }
        }
      }

      &-info {
        width: calc(100% - 500px);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          color: #2A52A4;
          text-align: center;
        }

        p {
          color: #7A7A7A;
          text-align: center;
        }
      }
    }
  }
}

.sign-in-modal, .cancel-subscription-modal {
  .ant-form-item-control-input-content {
    display: flex;
    gap: 20px;

    button[type="submit"] {
      background: #2A52A4;
      color: white;
    }
  }

  .ant-modal-header {
    text-align: center;
    padding-bottom: 20px;
  }
}

.carousel-dots {
  li {
    width: 5px!important;
    height: 45px!important;
    margin: 10px 0!important;

    button {
      width: 5px!important;
      height: 45px!important;
      background: #2a52a4!important;
    }
  }
}

.loader {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;

  span {
    font-size: 120px!important;

    i {
      width: 40px!important;
      height: 40px!important;
    }
  }
}