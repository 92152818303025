.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000080;
  z-index: 101;

  &-window {
    background: white;
    border-radius: 10px;
    width: 600px;
    min-height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    &-header {
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      color: #3E3E3E;
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;

      form {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }

    &-footer {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      button {
        background: #ffffff;
        color: rgba(0, 0, 0, 0.88);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
        padding: 5px 15px;
        height: 32px;
        border-radius: 6px;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        width: 85px;

        &.btn-loader {
          display: flex;
          justify-content: center;
          align-items: center;

          @keyframes spin {
            from {transform:rotate(360deg);}
            to {transform:rotate(0deg);}
          }

          svg {
            width: 20px;
            height: 20px;
            animation: spin 1s linear 0s infinite;

            path {
              fill: #FFFFFF;
            }
          }
        }

        &:hover {
          border: 1px solid #2A52A4;
          color: #2A52A4;
        }

        &.active {
          background: #2A52A4;
          border: 1px solid #2A52A4;
          color: #FFFFFF;

          &:hover {
            border: 1px solid #d9d9d9;
          }
        }
      }
    }

    &-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
      }

      &:hover {
        path {
          fill: #a42a2a;
        }
      }
    }
  }
}