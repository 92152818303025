.form-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;

  &-error {
    color: #ff4d4f;
    position: absolute;
    right: 10px;
    bottom: -18px;
    font-size: 14px;
  }

  &.error {

    input {
      border: 1px solid #ff4d4f;
      color: #ff4d4f;
    }
  }

  label {
    flex: 0 0 25%;
    text-align: right;

    &:before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  input {
    flex: 0 0 calc(75% - 40px);
    height: 30px;
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 0 10px;

    &:focus {
      border: 1px solid #2A52A4;
    }
  }
}

.form-subitem {
  display: flex;
  gap: 10px;
  justify-content: right;
  color: #707070;

  p {
    color: #2A52A4;
    margin: 0;
    cursor: pointer;

    &:hover {
      font-weight: 600;
    }
  }
}